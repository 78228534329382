import { InputProps } from "@/interfaces";
import clsx from "clsx";
import React from "react";
import AllIcons from "../../Icons";
;

export const Input: React.FC<
  InputProps & React.InputHTMLAttributes<HTMLInputElement>
> = ({
  id,
  type,
  name,
  register,
  textArea,
  customPlaceholder,
  error,
  label,
  onChangeCustomTextArea,
  onChangeCustom,
  rightClick,
  rightIcon,
  action,
  className,
  helperText,
  required,
  withBackground,
  defaultValue,
  leftIcon,
  rows = 3,
  ...props
}) => {
    const registerInput = register && register(name);
    const StylesInput = clsx(
      `${rightIcon ? "pr-8 pl-4" : leftIcon ? "pl-6" : "px-4"} w-full border border-gray-4 rounded-lg text-sm placeholder:text-sm placeholder:font-normal ${!textArea && "h-10"} resize-none placeholder:text-gray-4 ${withBackground && "bg-gray-3"}`,
      !error ? "placeholder-gray-1" : "placeholder-red-primary",
      !error
        ? "focus:outline-none focus:border-primary"
        : "focus:outline-none focus:border-red-primary "
    );
    return (
      <div className="flex flex-col gap-y-2">
        {label && (
          <div>
            <label
              className={clsx(
                "text-sm font-medium",
                !error
                  ? "text-black"
                  : "text-red-primary"
              )}
            >
              {" "}
              {label}{required && <span className="text-red-error">*</span>}
            </label>
          </div>
        )}
        <div>
          <div className={`relative`}>
            {leftIcon && (
              <div
                className={clsx("absolute inset-y-0 left-2 flex items-center pr-2 cursor-pointer w-4")}
                style={{ right: 6 }}
              >
                <AllIcons name={leftIcon} className={`${leftIcon === 'CloseIcon' ? 'h-3 w-3' : 'h-4 w-4'} text-gray-4`} />
              </div>
            )}
            <div className="">
              {textArea ? (
                <textarea
                  {...registerInput}
                  id={id}
                  name={name}
                  placeholder={customPlaceholder}
                  className={clsx(StylesInput, className)}
                  onChange={(e) => {
                    registerInput && registerInput.onChange(e);
                    onChangeCustomTextArea && onChangeCustomTextArea(e);
                  }}
                  autoComplete="off"
                  cols={10}
                  rows={rows}
                  disabled={props.disabled}
                  defaultValue={defaultValue}
                />
              ) : (
                <input
                  {...registerInput}
                  id={id}
                  type={type}
                  name={name}
                  placeholder={customPlaceholder}
                  className={clsx(StylesInput, className)}
                  autoComplete="off"
                  onChange={(e) => {
                    registerInput && registerInput.onChange(e);
                    onChangeCustom && onChangeCustom(e);
                  }}
                  defaultValue={defaultValue}
                  {...props}
                />
              )}
              {action && (
                <div
                  className="absolute"
                  style={{ top: "50%", transform: "translateY(-50%)", right: 16 }}
                >
                  {action({
                    name,
                    registerInput,
                  })}
                </div>
              )}
            </div>

            {rightIcon && (
              <div
                onClick={rightClick}
                className={clsx("absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer")}
                style={{ right: 6 }}
              >
                <AllIcons name={rightIcon} className={`${rightIcon === 'CloseIcon' ? 'h-3 w-3' : 'h-4 w-4'} text-gray-4`} />
              </div>
            )}
          </div>
          {((error && error.message) || helperText) && (
            <div
              className={clsx(
                "flex items-center pl-1 gap-x-4 mt-1 text-gray-1 text-xs",
                {
                  "text-red-primary": error && error.message,
                }
              )}
            >
              {error && error.message && (
                <div>
                  <AllIcons name="ExclamationErrorIcon" className="h-4 w-4 text-red-primary" />
                </div>
              )}

              <div>
                <p>{error?.message || helperText}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
